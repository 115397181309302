import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { GenericApiConfig, GenericApiService, JsonApiGenericApiConfig, merge, Op, Query, QueryOption, Search, SearchOption } from 'angular-jsonapi';
import { AppState } from 'app/app.state';
import { Organisation } from 'app/models/organisation.model';
import { ResourceWithOrganisation } from 'app/models/resource-with-organisation.model';
import { FieldNameId, FieldNameOrganisation } from 'app/shared/jsonapi/fields';
import { RequestOptions } from 'app/shared/jsonapi/request-options';
import { environment } from 'environments/environment';
import 'reflect-metadata';

const config: GenericApiConfig = {
    baseUrl: environment.url,
};
@Injectable({
    providedIn: 'root',
})
@JsonApiGenericApiConfig(config)
export class JsonApiService extends GenericApiService {
    private appState = inject(AppState);

    constructor(public http: HttpClient) {
        super(http);
    }

    generateQuery(options: RequestOptions): SearchOption {
        const filters: QueryOption[] = [];
        if (options.includes) {
            if (Array.isArray(options.includes)) {
                filters.push(Query.include(...options.includes));
            } else {
                filters.push(Query.include(options.includes));
            }
        }

        if (options.filters) {
            options.filters.forEach(filter => {
                const type = filter.type ?? (Array.isArray(filter.value) ? Op.In : Op.Eq);
                const children = filter.key.length > 1 ? filter.key.slice(1) : [];
                filters.push(Query.filterBy(type, merge(filter.key[0], ...children), filter.value));
            });
        }

        if (options.page !== undefined && options.page !== null && options.limit !== undefined && options.limit !== null) {
            filters.push(Query.pagination(options.limit, options.page));
        }

        if (options.organisationFilter) {
            const organisationId = this.appState.activeOrganisation()?.id;
            filters.push(Query.filterBy(Op.Eq, merge(FieldNameOrganisation, FieldNameId), organisationId));
        }

        return Search.withQueryOptions(...filters);
    }

    includeOrganisationRelationship<R extends ResourceWithOrganisation>(resource: R): R {
        if (!resource.Organisation()) {
            const organisationId = this.appState.activeOrganisation()?.id;
            const org = new Organisation({ id: organisationId });
            resource.withOrganisation(org);
        }
        return resource;
    }
}
