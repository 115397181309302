import { HttpErrorResponse, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { getApiResponse } from './getApiResponse';
import { JSONValue, MockRequest } from './mock-request.type';

const URL = environment.url;
const mockRequests: MockRequest[] = [];

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const mockRequestInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<any> => {
    const toastrService = inject(ToastrService);

    const newReq = req.clone();
    const mockedReq = mockRequests.find((request: any) => {
        const paths = newReq.url.split('?');
        return newReq.method === request.method && request.url === paths[0];
    });
    if (mockedReq) {
        console.log('Mocked request: ' + newReq.url);
        toastrService.info(`Request ${newReq.url} mocked`);
        if (newReq.url.includes(`${URL}/chats`) && newReq.url.includes('BOT') && !mockedReq.returnError) {
            return of(
                new HttpResponse({
                    status: 200,
                    body: getApiResponse((mockedReq.json as Array<JSONValue>).slice(1, 3)),
                })
            );
        }
        if (mockedReq.returnError) {
            return throwError(
                () =>
                    new HttpErrorResponse({
                        status: mockedReq.returnError,
                        error: `Error ${mockedReq.returnError}`,
                    })
            );
        }
        return of(
            new HttpResponse({
                status: 200,
                body: getApiResponse(mockedReq.json, mockedReq.totalCount, mockedReq.included),
            })
        );
    } else {
        return next(newReq);
    }
};
