import { FieldName, Op } from 'angular-jsonapi';

export class RequestOptions {
    page: number;
    limit: number;
    includes: FieldName[];
    filters: Filter[] = [];
    organisationFilter: boolean;

    constructor(page?: number, limit?: number, organisationFilter: boolean = false) {
        this.page = page;
        this.limit = limit;
        this.organisationFilter = organisationFilter;
    }

    addFilter(filter: Filter): void {
        this.filters.push(filter);
    }
    addInclude(include: FieldName | FieldName[]): void {
        if (!this.includes) {
            this.includes = [];
        }
        if (Array.isArray(include)) {
            this.includes = [...this.includes, ...include];
        } else {
            this.includes.push(include);
        }
    }
}

export class Filter {
    key: FieldName[];
    value: string | string[];
    type?: Op;
}
