import { FileHandle } from 'app/shared/types/file.types';
import { Observable } from 'rxjs';
import { DataWsProps } from 'app/models/data-ws.model';
import { StatusWsProps } from 'app/models/status-ws.model';

/* Interfaces */
export interface InfoListMessage {
    totalCount: number;
    customerId: string;
}

export interface MessageCache {
    text?: string;
    files?: FileHandle[];
}

export interface RelationshipsWsSubscription {
    targets: JsonApiRelationships;
}

/* Types */
export type Chats<T> = {
    [key in InternalDialogueAgent]?: T;
};

export type ChatsObservable<T> = {
    [key in InternalDialogueAgent]?: Observable<T>;
};

export type DialogueAgentsObservable<T> = {
    [key in string]?: Observable<T>;
};

export type MessageInWs = DataWsProps | StatusWsProps;

/* Enums */
export enum MessageStyles {
    bold = 'bold',
    body = 'body',
    primary = 'primary',
}

export enum MessageDisplayTypes {
    text = 'text',
    button = 'button',
    menu = 'menu',
    markdown = 'markdown',
    file = 'file',
    caption = 'caption',
    formReply = 'formreply',
    order = 'order',
    catalogue = 'catalogue',
}

export enum MessageButtonTypes {
    open_link = 'open_link',
    open_dialer = 'open_dialer',
    open_mail = 'open_mail',
}

export enum InternalDialogueAgent {
    AGENT = 'Agent',
    CHATBOT = 'Chatbot',
    SEARCH = 'search',
}

export enum ActionWs {
    SUBSCRIBE = 'SUBSCRIBE',
    UNSUBSCRIBE = 'UNSUBSCRIBE',
    AUTHENTICATE = 'AUTHENTICATE',
}

export enum EventWs {
    DATA = 'DATA',
    STATUS = 'STATUS',
}

export type EventTypeWs = ChatEventType | ChannelGroupEventType | DialogueAgentEventType | MessageEventType;

export enum ChatEventType {
    UPDATED = 'chats.updated',
}

export enum ChannelGroupEventType {
    UPDATED = 'channel-groups.updated',
}

export enum DialogueAgentEventType {
    NEW = 'dialogue-agents.created',
}

export enum MessageEventType {
    NEW = 'messages.created',
    UPDATED = 'messages.updated',
}

export enum ChatUpdatedEvent {
    CHAT_OUT = 'chat-out',
    DIALOGUE_AGENT_UPDATED = 'dialogue-agent-updated',
    CHAT_KEEP = 'chat-keep',
}

export enum MessageDirection {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING',
}

export enum DeliveryErrorType {
    INTERNAL = 'INTERNAL',
    PROVIDER_UNAVAIABLE = 'PROVIDER_UNAVAIABLE',
    TIME_WINDOW_24H = 'TIME_WINDOW_24H',
    MAX_CHARS = 'MAX_CHARS',
}

export enum DeliveryStatus {
    ERROR = 'Error',
    DELETED = 'Deleted',
    SENT = 'Sent',
    DELIVERED = 'Delivered',
    READ = 'Read',
}

export enum DialogueStatus {
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
}

export enum MediaFileStatus {
    PENDING = 'PENDING',
    UPLOADING = 'UPLOADING',
    UPLOADED = 'UPLOADED',
    FAILED = 'FAILED',
}

export const ChatNotFound = 404;
