import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account, Role } from 'app/shared/types/account.types';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject, map } from 'rxjs';

const AGENT_ID = 'agentId';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    agentName$: Observable<string>;
    role$: Observable<Role>;
    account$: Observable<Account>;

    private _agentName = new BehaviorSubject<string>('');
    private _role = new BehaviorSubject<Role>(undefined);
    private _account = new BehaviorSubject<Account>(undefined);
    private url = environment.url;

    constructor(private httpClient: HttpClient) {
        this.agentName$ = this._agentName.asObservable();
        this.role$ = this._role.asObservable();
    }

    getAgentId(): string {
        return localStorage.getItem(AGENT_ID);
    }

    setAgentId(agentId: string): void {
        localStorage.setItem(AGENT_ID, agentId);
    }

    removeAgentId(): void {
        localStorage.removeItem(AGENT_ID);
    }

    getAgentName(): string {
        return this._agentName.value;
    }

    setAgentName(name: string): void {
        this._agentName.next(name);
    }

    getAccount(): Observable<Account> {
        return this.httpClient.get<JsonApiResponse<any>>(`${this.url}/account-permissions?include=roles,permissions`).pipe(
            map((apiResponse: JsonApiResponse<any>) => {
                const account = new Account(apiResponse.data, apiResponse.included);
                this._account.next(account);
                const superadminRole = this._account.getValue().roles.find(role => role.id === Role.SUPERADMIN);
                if (superadminRole) {
                    this._role.next(superadminRole.id);
                }
                return account;
            })
        );
    }

    setRoleByOrg(orgId: string): void {
        const superadminRole = this._account.getValue().roles.find(role => role.id === Role.SUPERADMIN);
        const role = superadminRole ? superadminRole.id : this.getRoleByOrg(orgId);
        this._role.next(role);
    }

    getRoleByOrg(orgId: string): Role {
        return this._account.getValue().roles.find(role => role.targets.includes(orgId))?.id;
    }

    getRole(): Role {
        return this._role.getValue();
    }

    isAdmin(): boolean {
        return this._role.getValue() === Role.SUPERADMIN || this._role.getValue() === Role.ORG_SUPERADMIN || this._role.getValue() === Role.ORG_ADMIN;
    }

    isSuperadmin(): boolean {
        return this._role.getValue() === Role.SUPERADMIN || this._role.getValue() === Role.ORG_SUPERADMIN;
    }

    isAppSuperadmin(): boolean {
        return this._role.getValue() === Role.SUPERADMIN;
    }

    isSuperadminByOrg(orgId: string): boolean {
        const superadminRole = this._account.getValue().roles.find(role => role.id === Role.SUPERADMIN);
        const role = this.getRoleByOrg(orgId);
        return !!superadminRole || role === Role.ORG_SUPERADMIN;
    }

    getAllTargets(): string[] {
        return (
            this._account
                .getValue()
                .roles.map(role => role.targets)
                .reduce((pre, cur) => pre.concat(cur)) ?? []
        );
    }

    getTargetsByRole(role: Role): string[] {
        return this._account.getValue().roles.find(r => r.id === role)?.targets;
    }
}
