import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Organisation } from './models/organisation.model';

@Injectable({
    providedIn: 'root',
})
export class AppState {
    activeOrganisation$: Observable<Organisation>;

    private _activeOrganisation = new BehaviorSubject<Organisation>(null);

    constructor() {
        this.activeOrganisation$ = this._activeOrganisation.asObservable();
    }

    setActiveOrganisation(org: Organisation): void {
        this._activeOrganisation.next(org);
    }

    activeOrganisation(): Organisation {
        return this._activeOrganisation.value;
    }
}
