import { FieldName } from 'angular-jsonapi';

export const FieldNameId: FieldName = 'id';
export const FieldNameOrganisation: FieldName = 'organisation';
export const FieldNameChannelGroup: FieldName = 'channelGroup';
export const FieldNameAgent: FieldName = 'agent';
export const FieldNameApi: FieldName = 'api';
export const FieldNameCentre: FieldName = 'centre';
export const FieldNameKind: FieldName = 'kind';
export const FieldNameDate: FieldName = 'date';
export const FieldNameType: FieldName = 'type';
export const FieldNameGroup: FieldName = 'group';
export const FieldNameCredentials: FieldName = 'credentials';
export const FieldNameDialogueAgent: FieldName = 'dialogueAgent';
export const FieldNameCustomer: FieldName = 'customer';
export const FieldNameRecipient: FieldName = 'recipient';
export const FieldNameStatus: FieldName = 'status';
export const FieldNameChatVisibility: FieldName = 'chatVisibility';
export const FieldNamedDaloguePermissions: FieldName = 'dialoguePermissions';
export const FieldNameAccount: FieldName = 'account';
export const FieldNameAllowedChannelGroups: FieldName = 'allowedChannelGroups';
export const FieldNameAllowedAgents: FieldName = 'allowedAgents';
