import { Attribute, Resource, ResourceConfig, ResourceProps } from 'angular-jsonapi';
import { ResourceTypeOrganisation } from 'app/shared/resource/resource';
import { CentreProps } from './centre.model';

@ResourceConfig({
    type: ResourceTypeOrganisation,
})
export class Organisation extends Resource {
    @Attribute()
    tradeName: string;

    @Attribute()
    legalName: string;

    @Attribute()
    onlineStoreWebsite: string;

    @Attribute()
    suspendedSince: Date;

    constructor(data: Partial<OrganisationProps>) {
        super(data);
        if (data) {
            this.tradeName = data.tradeName;
            this.legalName = data.legalName;
            this.onlineStoreWebsite = data.onlineStoreWebsite;
            this.suspendedSince = new Date(data.suspendedSince);
        }
    }
}

export type OrganisationProps = Partial<ResourceProps> & {
    tradeName: string;
    legalName: string;
    onlineStoreWebsite: string;
    centres: Partial<CentreProps>[];
    suspendedSince: string;
};
