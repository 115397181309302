import { ResourceConfig, Attribute, ResourceProps, Relationship, Resource } from 'angular-jsonapi';
import { ResourceTypeChat } from 'app/shared/resource/resource';
import { ChannelGroupProps } from './channel-group.model';
import { Dialogue } from './dialogue.model';
import { Customer, CustomerProps } from './customer.model';

@ResourceConfig({
    type: ResourceTypeChat,
})
export class Chat extends Resource {
    @Attribute()
    pendingToReplyCount: number;

    @Attribute()
    displayPriority: number;

    @Relationship({ type: Dialogue })
    private activeDialogue: Dialogue;

    @Relationship({ type: Customer, serializedName: 'recipient' })
    private customer: Customer;

    @Relationship({ type: Resource })
    private lastMessage: Resource;

    @Relationship({ type: Resource })
    private channelGroup: Resource;

    constructor(props: Partial<ChatProps>) {
        super(props);
        if (props) {
            this.pendingToReplyCount = props.pendingToReplyCount;
            this.displayPriority = props.displayPriority;

            if (props.activeDialogue) {
                this.activeDialogue = new Dialogue(props.activeDialogue);
            }

            if (props.customer) {
                this.customer = new Customer(props.customer);
            }

            if (props.lastMessage) {
                this.lastMessage = new Resource(props.lastMessage);
            }

            if (props.channelGroup) {
                this.channelGroup = new Resource(props.channelGroup);
            }
        }
    }

    ChannelGroup(): Resource {
        return this.channelGroup;
    }

    LastMessage(): Resource {
        return this.lastMessage;
    }

    Customer(): Customer {
        return this.customer;
    }

    ActiveDialogue(): Dialogue {
        return this.activeDialogue;
    }
}

export type ChatProps = Partial<ResourceProps> & {
    pendingToReplyCount: number;
    displayPriority: number;
    channelGroup: Partial<ChannelGroupProps>;
    lastMessage: Partial<ResourceProps>;
    customer: Partial<CustomerProps>;
    activeDialogue: Partial<ResourceProps>;
};
